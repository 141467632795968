import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../../layouts/layout";

import { HeaderTitle } from "../../components/HeaderTitle";
import { HtmlContent } from "../../components/HtmlContent";
import { WavySeparator } from "../../components/WavySeparator";

import * as helperStyles from "../helpers.module.css";
import {
  ButtonPrimaryLarge,
  addUtmParams,
  urlTesterRegistration,
} from "@userbrain/website-ui";

const Sales = () => (
  <Layout title={"Userbrain Tester [Deutsch]"}>
    <article>
      <HeaderTitle titleWidth={"13em"}>Userbrain Tester [Deutsch]</HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <h2>Description</h2>
          <p>
            At Userbrain, we are always looking for individuals who can help us
            improve the user experience of websites, prototypes, and mobile
            apps. As a Userbrain Tester, you will play a crucial role in our
            clients' user research by providing valuable feedback on various
            digital products. This is a unique opportunity to earn money from
            home, on your own schedule, without any specific experience
            required.
          </p>

          <h2>About the Job</h2>
          <ul>
            <li>Location: Remote (worldwide) 🌎</li>
            <li>Compensation: $5 per completed test 💸</li>
          </ul>

          <h2>How does it work?</h2>
          <ol>
            <li>
              Sign up and download our Userbrain Recorder for Google Chrome,
              iOS, or Android to start testing websites.
            </li>
            <li>
              Speak your thoughts while going through a set of tasks for about 5
              to 20 minutes.
            </li>
            <li>Receive new tests and get paid $5 per test via PayPal.</li>
          </ol>

          <h2>What happens after you apply?</h2>
          <ol>
            <li>
              You’ll be asked to record a Qualification Test. Don’t worry, we’ll
              give you a tour and a guide first, so you know exactly how
              everything works.
            </li>
            <li>
              We then review your video and give feedback on how and what you
              might improve in future tests.
            </li>
            <li>
              You will start receiving paid tests if approved as a Userbrain
              tester.
            </li>
          </ol>

          <h2>Requirements</h2>
          <ul>
            <li>
              You have a reliable internet connection and a device to test on
              (computer, smartphone, or tablet).
            </li>
            <li>You are fluent in English.</li>
            <li>
              You can articulate your thoughts clearly and provide constructive
              feedback.
            </li>
          </ul>

          <h2>About the application process</h2>
          <ol>
            <li>We receive your application</li>
            <li>You complete a Qualification Test</li>
            <li>We review your test and provide feedback</li>
            <li>If approved, you start receiving paid tests</li>
          </ol>

          <h2>Beschreibung</h2>
          <p>
            Wir bei Userbrain sind immer auf der Suche nach Personen, die uns
            dabei helfen, die User Experience von Websites, Prototypen und
            mobilen Apps zu verbessern. Als Userbrain-Tester spielst du eine
            entscheidende Rolle in der Nutzerforschung unserer Kunden, indem du
            wertvolles Feedback zu verschiedenen digitalen Produkten gibst. Dies
            ist eine einmalige Gelegenheit, von zu Hause aus Geld zu verdienen,
            nach eigenem Zeitplan und ohne besondere Vorkenntnisse.
          </p>

          <h2>Über den Job</h2>
          <ul>
            <li>Standort: Remote (weltweit) 🌎</li>
            <li>Vergütung: $5 pro abgeschlossenem Test 💸</li>
          </ul>

          <h2>Wie funktioniert es?</h2>
          <ol>
            <li>
              Melden Sie sich an und laden Sie unseren Userbrain Recorder für
              Google Chrome, iOS oder Android herunter, um mit dem Testen von
              Websites zu beginnen.
            </li>
            <li>
              Sprechen Sie Ihre Gedanken, während Sie eine Reihe von Aufgaben
              für etwa 5 bis 20 Minuten durchgehen.
            </li>
            <li>
              Sie erhalten neue Tests und werden mit 5 $ pro Test über PayPal
              bezahlt.
            </li>
          </ol>

          <h2>Was passiert, nachdem Sie sich beworben haben?</h2>
          <ol>
            <li>
              Sie werden gebeten, einen Qualifizierungstest aufzunehmen. Keine
              Sorge, wir geben Ihnen zuerst eine Führung und einen Leitfaden,
              damit Sie genau wissen, wie alles funktioniert.
            </li>
            <li>
              Anschließend prüfen wir Ihr Video und geben Ihnen Feedback, wie
              und was Sie bei künftigen Tests verbessern können.
            </li>
            <li>
              Wenn du als Userbrain-Tester zugelassen wirst, erhältst du
              bezahlte Tests.
            </li>
          </ol>

          <h2>Anforderungen</h2>
          <ul>
            <li>
              Du hast eine zuverlässige Internetverbindung und ein Gerät zum
              Testen (Computer, Smartphone oder Tablet).
            </li>
            <li>Du sprichst fließend Englisch.</li>
            <li>
              Du kannst deine Gedanken klar artikulieren und konstruktives
              Feedback geben.
            </li>
          </ul>

          <h2>Über den Bewerbungsprozess</h2>
          <ol>
            <li>Wir erhalten deine Bewerbung</li>
            <li>Du absolvierst einen Qualifizierungstest</li>
            <li>Wir prüfen deinen Test und geben Feedback</li>
            <li>Wenn du zugelassen wirst, erhältst du bezahlte Tests</li>
          </ol>

          <p style={{ marginTop: "3rem" }}>
            <ButtonPrimaryLarge
              customTag={"a"}
              href={addUtmParams(
                urlTesterRegistration + "/de",
                "website",
                "careers"
              )}
            >
              Userbrain-Tester werden
            </ButtonPrimaryLarge>
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default Sales;
